<template>
  <div class="agreeWrap">
    <div class="container-bd">
      <div class="c-left">
        <div class="blue">
          <p>
            <span>【重要】</span
            >在您註銷您的嗨文帳號之前，請充分閱讀、理解並同意下列事項:
          </p>
          <p>
            <span>【特別提示】</span
            >註銷嗨文帳號爲不可恢復的操作，除您已授權提供至第三方的信息以外，註銷帳號後您將無法再使用本嗨文帳號或找回您瀏覽、收藏、參與活動的任何內容或信息(即使您使用相同的手機號碼再次註冊並使用嗨文)。請確保您所申請註銷的嗨文帳號應當是您本人依照《嗨文用戶協議》的約定創建並由本公司提供給您本人的帳號。您應當依照本公司規定的程序或網站_上的提示註銷嗨文帳號。建議您在註銷前自行備份嗨文帳號相關的所有信息，並請確認與本嗨文帳號相關的所有服務均已進行妥善處理。註銷完成後，嗨文將刪除您的個人信息或對其進行匿名化處理(法律法規另有規定的除外)。請您知悉並理解，根據相關法律法規規定相關日誌記錄嗨文將保留不少於6個月的時間。
          </p>
        </div>
        <div class="mod">
          <div class="bd">
            <h4>
              1.我們對您註銷嗨文帳號的決定深表遺憾。如果您仍決定繼續進行帳號註銷，您的帳號需同時滿足以下條件:
            </h4>
            <ol class="list decimal-list">
              <li>
                1.1帳號處於安全狀態:帳號處於正常使用狀態，無被盜風險
              </li>
              <li>
                1.2帳號財產已結清，沒有未完成或存在爭議的服務:帳號中沒有資產、欠款、未結清的資金和虛擬權益;本帳號及通過本帳號接入的第三方中沒有未完成或存在爭議的服務。
              </li>

              <li>
                1.3帳號權限解除:帳號已解除與第三方產品、網站授權登錄或綁定關係。
              </li>
              <li>
                1.4帳號無任何未結爭議糾紛，包括投訴舉報或被投訴舉報。
              </li>
            </ol>
            <h4>
              2.嗨文帳號註銷後，您將無法登錄、使用本嗨文帳號，也將無法找回本嗨文帳號中及與帳號相關的任何內容或信息，包括但不限於:
            </h4>
            <ol class="list decimal-list">
              <li>
                2.1.個人已提交的身份信息、帳號信息等信息。
              </li>
              <li>
                2.2.已收藏的作品、點贊、評論、轉發、互動、瀏覽歷史等內容數據。
              </li>
              <li>
                2.3.通過本嗨文帳號使用、授權登錄或綁定本嗨文帳號後使用的嗨文或第三方服務的相關記錄將無法找回。您將無法再登錄、使用上述服務，通過上述服務曾獲得的資產或虛擬權益等財產性利益視爲您同意放棄，將無法繼續使用。您理解並同意，嗨文無法協助您重新恢復上述服務。
              </li>
              <li>
                2.4.帳號內錢包賬戶餘額、鑽石票，推薦票、獎勵、收益、實名認證信息、交易記錄、已綁定的銀行卡等信息將被作廢及刪除。請確認帳號內無任何資產或虛擬權益，您曾獲得的資產虛擬權益等財產性利益視爲您同意放棄，將無法繼續使用。您理解並同意，您將放棄嗨文的資產或權益，嗨文無法協助您恢復上述服務，也無法找回帳號相關的任何內容或信息。
              </li>
            </ol>
            <h4>
              3.嗨文帳號註銷將導致本公司終止爲您提供服務，依《嗨文用戶協議》和《隱私政策》等約定的雙方權利義務終止，依法律法規另有規定、本頁面其他條款另行約定不得終止的或依其性質不能終止的除外。
            </h4>

            <h4>
              4.在您的嗨文帳號註銷期間，如果您的帳號涉及爭議糾紛，包括但不限於:投訴、舉報、訴訟、仲裁、國家有權機關調查等，您理解並同意，嗨文有權自行決定是否終止本帳號的註銷而無需另行得到您的同意。
            </h4>

            <h4>
              5.註銷本嗨文帳號並不代表本嗨文帳號註銷前的賬戶行爲和相關責任得到豁免或減輕。
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
.agreeWrap
   color #636778
   padding 49px 24px 30px
   font-size 26px
   letter-spacing 2px
   line-height 42px
   h6
        font-size 30px
        margin 15px 0
   h3
        font-size 32px
        margin 30px 0
   h4
        margin 30px 0
   .blue
        padding 28px 40px
        background:rgba(237,241,247,1);
        border-radius:14px;
        span
            font-weight 600
    .mod
        padding 36px 12px
        font-size 28px
        li
            text-indent 20px
</style>
